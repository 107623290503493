export interface IEventDTO {
  event: string
  userGgId: string
  targetUserGgId?: string | null,
  created: string,
  context: { [key: string]: any }
};

export enum EventNames {
  HIRE_REPORTED = "hire_reported",
  TASKS_PAGE_VISITED = "tasks_page_visited",
  GENOME_TOKEN_GENERATED = "genome_token_generated",
  USER_LOGGED_IN = "user_logged_in",
  OPPORTUNITY_REVIEW_UPDATED = "opportunity_review_updated",
  DATA_POINT_LINK_COPIED = "data_point_link_copied",
  TASK_LINK_COPIED = "task_link_copied",
  TASK_ASSIGNEE_CHANGED = "task_assignee_changed",
  OPPORTUNITY_OPERATOR_SETTED = "opportunity_operator_setted",
  ALL_MESSAGES_PAGE_VISITED = "all_messsages_page_visited",
  ALL_MESSAGES_RETRIEVED = "all_messages_retrieved",
  CCG_EMAIL_UPDATED = "ccg_email_updated",
  JOB_OPENING_APPLICATION_STARTED_ON_BEHALF = "job-opening-application-started-on-behalf",
  CANDIDATE_ENROLLED_IN_RECRUITER_BOT = "candidate-enrolled-in-recruiter-bot",
  CANDIDATE_UNROLLED_FROM_RECRUITER_BOT = "candidate-unrolled-from-recruiter-bot",
  CANDIDATE_SETTINGS_IN_RECRUITER_BOT_UPDATED = "candidate-settings-in-recruiter-bot-updated",
  CANDIDATE_NOTIFICATIONS_IN_RECRUITER_BOT_POSTPONED = "candidate-notifications-in-recruiter-bot-postponed",
  CANDIDATE_MARKED_AS_UNINTERESTED = "candidate-marked-as-uninterested",
  CANDIDATE_PREFERENCES_UPDATED = "candidate-preferences-updated",
  TS_CANDIDATE_IMPORTED = "ts-candidate-imported"
}

export interface IEventLoggerFunctionArguments {
  eventName: string
  status?: string
  targetUserGgId?: string,
  context: { [key: string]: any }
}
