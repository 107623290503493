export interface INotification {
  id?: number;
  message: string
  type: "error" | "success" | "info" | "warning",
  delay?: number
}

export interface ISaveNotificationReceivedDTO {
  receivedAt: string,
  isAudioEnabled: boolean,
  isBrowserTabActive: boolean
}

export class Notification implements INotification {
  id?: number;
  message: string;
  type: "error" | "success" | "info" | "warning";
  delay?: number;

  constructor(data: INotification) {
    this.id = Math.floor(Math.random() * (1000 - 10) + 1000);
    this.message = data.message;
    this.type = data.type;
    this.delay = data.delay ?? 6000;
    this.handleNotified();
  }

  handleNotified(): void {
    setTimeout(() => {
      if (process.browser) {
        window.$nuxt.$store.dispatch("unsetNotification", this.id);
      }
    }, this.delay);
  }
}
