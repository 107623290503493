import SnowplowSchemas from "@/assets/ts/SnowplowSchemas";
import { IPerson } from "@/services/DTOs/Person";
import { ISnowplowContext } from "@/services/DTOs/tracking/SnowplowContext";

export interface IUserSnowplowContextData {
  isTestAccount: boolean;
  isTorrex: boolean;
  subjectId?: string;
  ggId?: string;
  name?: string;
  email?: string;
  phone?: string;
}

export interface IUserContextSchema {
  schema: string;
  data: IUserSnowplowContextData;
}

export class UserSnowplowContext implements ISnowplowContext {
  public isTestAccount: boolean;
  public isTorrex: boolean;
  public subjectId?: string;
  public name?: string;
  public ggId?: string;
  public email?: string;
  public phone?: string;

  constructor(data: IPerson) {
    this.isTestAccount = false;
    this.isTorrex = data.isTorrex;
    this.name = data.name;
    this.ggId = data.ggId;
    this.email = data.email;
    this.phone = data.phone;
  }

  public parseSchema(): IUserContextSchema {
    return {
      schema: SnowplowSchemas.UserContext,
      data: this
    };
  }
}
