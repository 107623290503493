import { isBoolean, isEmpty, isNumber, isObject, omitBy } from "lodash-es";

import { IPermissionMicroDTO } from "@/services/DTOs/Permission";

export interface ISelectOption {
  text: string,
  disabled?: boolean
  divider?: boolean
  header?: string
}

export interface ITableHeader<T = string> extends ISelectOption {
  value: T
  groupable?: boolean,
  sortable?: boolean,
  align?: "start" | "center" | "end",
  filterable?: boolean,
  divider?: boolean,
  class?: string | string[],
  cellClass?: string | string[],
  width?: string | number,
  filter?: (value: T, search: string, item: any) => boolean,
  sort?: (a: T, b: T) => number
}

export interface IStringSelectOption extends ISelectOption {
  value: string
}

export interface IStringOption {
  value: string
}

export interface IMenuOption {
  text: string,
  to: string,
  icon?: string,
  desc?: string,
  disabled?: () => boolean,
  show?: () => boolean,
  permissions?: IPermissionMicroDTO[]
}

export interface IGenericSelectOption<T> extends ISelectOption {
  value: T
}

export interface INumberSelectOption extends ISelectOption {
  value: number
}

export interface IStringSelectOptionWithColor extends IStringSelectOption { color: string }

export interface IActionLink {
  href: string
  label: string
}

export interface Dictionary<T> {
  [index: string]: T;
}

export const SelectItemHeader = (header: string, divider = false): IStringSelectOption => ({
  text: header,
  header,
  value: header,
  divider
});

export interface IMinMax<T extends string | number = number> {
  min: T,
  max: T
};

export abstract class Base64QueryFilter<F> {
  static FromRouteParam<T>(query: string): T {
    return Reflect.construct(this, [JSON.parse(atob(query))]) as T;
  }

  toQueryParam(param: string): { [key: string]: string } {
    return { [param]: btoa(JSON.stringify(this)) };
  }

  getPreparedQuery(overrides?: Partial<F>): Partial<F> {
    return omitBy({ ...this, ...overrides }, (f) => {
      if (isNumber(f) || (isObject(f) && !isEmpty(f)) || isBoolean(f)) {
        return false;
      } else {
        return !(f as any)?.length;
      }
    }) as Partial<F>;
  }

  copy<T extends Base64QueryFilter<F>>(overrides?: Partial<F>): T {
    return Reflect.construct(this.constructor, [this.getPreparedQuery(overrides)]);
  }
}

export enum IActionMode {
  VIEW = "view",
  CREATE = "create",
  UPDATE = "update"
}

export interface ITabOption {
  title: string
  value: string
  icon: string
  disabled: boolean
  fabIcon: string
}

export interface IStepperStep<T> {
  title: string;
  step: T;
  complete: boolean;
  editable: boolean;
}

export interface ICreatedID {
  id: number
}

export interface IUpdatedRecords {
  updatedRecords: number
}

export interface IMessageResponse {
  message: string
}

export enum ForwardedService {
  BIO = "bio",
  DISCOVERY = "discovery",
  IRUO = "iruo",
  WONKRU = "wonkru",
  HERMES = "hermes",
  PUZZLES = "puzzles",
  JEDI = "jedi",
  PROXIMA = "proxima"
}

export type UnionKeys<T> = T extends T ? keyof T : never;

export type StrictUnionHelper<T, TAll> = T extends any
  ? T & Partial<Record<Exclude<UnionKeys<TAll>, keyof T>, never>>
  : never;

export type StrictUnion<T> = StrictUnionHelper<T, T>

type EnumType = { [key: string]: string | number };

export const convertEnumToOptions = <T extends EnumType>(enumObj: T) => {
  return Object.entries(enumObj)
    .filter(([key, value]) => typeof value === "string" || (typeof key === "string" && !parseInt(key, 10)))
    .map(([key, value]) => ({
      text: key.replace(/_/g, " ").toLowerCase().replace(/\b\w/g, char => char.toUpperCase()),
      value
    }));
};
