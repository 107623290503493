import { IStringSelectOption } from "./Misc";

export enum PermissionCategory {
  API = "api",
  ACTION = "action",
  PAGE = "page"
}

export interface PermissionTinyDTO {
  id: number;
  name: string;
  category: PermissionCategory;
}

export type IPermissionMicroDTO = Omit<PermissionTinyDTO, "id">

export interface IPermissionDTO extends PermissionTinyDTO {
  description: string;
  created: string | Date;
  deactivated?: string | Date;
}

export interface ISavePermissionDTO extends IPermissionMicroDTO {
  description: string;
}

export interface IUpdatePermissionDTO extends Partial<IPermissionMicroDTO> {
  description?: string;
  deactivate?: boolean;
}

export const permissionCategorySelectOptions: IStringSelectOption[] = [
  { text: "API", value: PermissionCategory.API },
  { text: "Action", value: PermissionCategory.ACTION },
  { text: "Page", value: PermissionCategory.PAGE }
];

export interface ISavePermissionGroupDTO {
  group: string;
  isActive: boolean;
  permissions: number[];
}

export interface IPermissionGroupDTO {
  group: string;
  created: string | Date;
  permissions: PermissionTinyDTO[];
  deactivated?: string | Date;
}

export interface IPermissionStatusDTO {
  id: number;
  isActive: boolean;
}

export interface ISaveOperatorPermissionDTO {
  operatorGgId: string;
  permissions: IPermissionStatusDTO[];
}

export interface IOperatorsPermissionsDTO {
  [operatorGgId: string]: PermissionTinyDTO[];
}

export type IUpdateOperatorsPermissionDTO = Map<string, IPermissionStatusDTO[]>;

export const emptyPermissionTiny = (): PermissionTinyDTO => ({
  id: 0,
  name: "test-name",
  category: PermissionCategory.PAGE
});

export const emptyPermission = (): IPermissionDTO => ({
  id: 0,
  name: "test-name",
  category: PermissionCategory.PAGE,
  description: "test-description",
  created: "2020-01-01T12:34:56Z"
});

export const emptyPermissionGroup = (): IPermissionGroupDTO => ({
  group: "test-group",
  permissions: [],
  created: "2020-01-01T12:34:56Z"
});

export interface IPagePermissionMeta {
  directive: "exact" | "inclusive";
  permissions: IPermissionMicroDTO[];
};
