import firebase from "firebase/compat";
import { isEmpty } from "lodash-es";

import { PermissionTinyDTO } from "./Permission";
import { ICCGCreationData, ICCGCreationResponse, ICCGCreationWithLinkedinURLData, ISocialNetworkDTO } from "./RecruiterChatbot";
import { createSelectOptionsFromEnum } from "@/helpers/ArrayHelpers";
import { isTorrexEmail, isTorrexTester } from "@/helpers/TorreHelpers";
import { Base64QueryFilter, IStringSelectOption } from "@/services/DTOs/Misc";

export interface IAuthUser {
  uid: string
  operatorId: number,
  displayName?: string
  email: string
  phoneNumber?: string
  photoURL?: string,
  role: string,
  ggId: string
}

export enum OperatorStatus {
  ACTIVE = "active",
  INACTIVE = "inactive",
  ALL = "all"
}

export enum OperatorRoles {
  UNKNOWN = "unknown",
  GENIUS = "torre-genius",
  ACCOUNT_MANAGER_AGILE = "account-manager-agile",
  ACCOUNT_MANAGER_SELF_SERVICE = "account-manager-self-service",
  ALGORITHM_OPERATOR = "algorithm-operator",
  ASSOCIATE_FINANCE_ANALYST = "associate-finance-analyst",
  BUSINESS_COACH = "business-coach",
  BUSINESS_OPERATIONS_COORDINATOR = "business-operations-coordinator",
  CANDIDATE_RECRUITER = "candidate-recruiter",
  CANDIDATE_SUCCESS_COORDINATOR = "candidate-success-coordinator",
  CANDIDATE_SUCCESS_FREELANCER = "candidate-success-freelancer",
  CEO = "ceo",
  DATA_ANALYST = "data-analyst",
  DEDICATED_RECRUITER = "dedicated-recruiter",
  DESIGNER = "designer",
  ENGINEER = "engineer",
  FREELANCER = "freelancer",
  HEAD_OF_APPLICANT_ACQUISITION = "head-of-applicant-acquisition",
  HEAD_OF_CANDIDATE_RECRUITMENT = "head-of-candidate-recruitment",
  HEAD_OF_TSO_AGILE = "head-of-tso-agile",
  HEAD_OF_TSO_SELF_SERVICE = "head-of-tso-self-service",
  HEAD_OF_SALES = "head-of-sales",
  OPERATIVE_COACH = "operative-coach",
  OPERATIONAL_QA_COORDINATOR = "operational-qa-coordinator",
  OPERATIONAL_EXCELLENCE_COORDINATOR = "operational-excellence-coordinator",
  RECRUITING_MANAGER = "recruiting-manager",
  SALES = "sales",
  SENIOR_RECRUITER = "senior-recruiter",
  TALENT_SEEKER_OPERATOR = "talent-seeker-operator",
  TORRE_GENIUS = "torre-genius",
  TALENT_ACQUISITION_MANAGER = "talent-acquisition-manager"
}

export const operatorRolesOptions = createSelectOptionsFromEnum(OperatorRoles);

export enum OpportunityOperatorRoles {
  RECRUITING_MANAGER = "recruiting_manager",
  CANDIDATE_RECRUITER = "candidate_recruiter",
  RECRUITER = "recruiter"
}

export const OperatorReflectionRolesOptions = [
  {
    key: "Recruiting manager",
    value: OperatorRoles.RECRUITING_MANAGER
  },
  {
    key: "Candidate recruiter",
    value: OperatorRoles.CANDIDATE_RECRUITER
  },
  {
    key: "Senior recruiter",
    value: OperatorRoles.SENIOR_RECRUITER
  },
  {
    key: "Dedicated recruiter",
    value: OperatorRoles.DEDICATED_RECRUITER
  },
  {
    key: "Unknown",
    value: OperatorRoles.UNKNOWN
  }
];

export const OperatorRolesGeneralOptions = [
  ...OperatorReflectionRolesOptions,
  {
    key: "Engineer",
    value: OperatorRoles.ENGINEER
  },
  {
    key: "Data analyst",
    value: OperatorRoles.DATA_ANALYST
  }
];

export interface IOperatorGroupDTO {
  id: number,
  name: string,
  isLeader: boolean,
  created: string,
  deactivated?: string
}

export interface IPersonSnapshot {
  subjectId?: string,
  ggId: string,
  username?: string,
  name: string,
  professionalHeadline?: string,
  picture?: string,
}

export interface ISimplePerson extends Omit<IPersonSnapshot, "subjectId"> {
  id: string,
  subjectId?: number,
  publicId: string,
  currentRole?: string,
  theme: string,
  pictureThumbnail?: string,
  phoneNumber?: string | null,
  email?: string
}

export interface IOperatorFlagsDTO {
  isAvailable: boolean;
  speaksEnglish: boolean;
  isMessagesTaskEnabled: boolean;
}

export interface IOperator {
  id: number,
  bio: ISimplePerson
  operatorGgId: string
  isAdmin: boolean
  role: OperatorRoles
  permissions: PermissionTinyDTO[]
  operatorFlags: IOperatorFlagsDTO
  slackId: string
  created: string | Date
  deactivated?: string
}

export interface IUpdateOperatorDTO {
  email?: string;
  isAdmin?: boolean;
  role?: string;
  operatorFlags: IOperatorFlagsDTO;
  slackId?: string;
  deactivate?: boolean;
}

export enum OpportunityOperatorRolesEnum {
  AGILE_RECRUITER = "agile-recruiter",
  TALENT_SEEKER_ACQUISITION = "talent-seeker-acquisition",
  CANDIDATE_RECRUITER = "candidate-recruiter"
}

export const OpportunityOperatorRolesOption: IStringSelectOption[] = [
  { text: "Agile Recruiter", value: OpportunityOperatorRolesEnum.AGILE_RECRUITER },
  { text: "Talent Seeker Acquisition(TAM)", value: OpportunityOperatorRolesEnum.TALENT_SEEKER_ACQUISITION },
  { text: "Candidate Recruiter", value: OpportunityOperatorRolesEnum.CANDIDATE_RECRUITER }
];

export interface IJsGgId {
  jsGgid?: string
}

export interface IBioFindQuery {
  identifier: "email" | "ggId" | "phone" | "publicId"
  value: string
}

export class BioFindQuery extends Base64QueryFilter<IBioFindQuery> {
  public identifier: "email" | "ggId" | "phone" | "publicId" = "publicId";
  public value: string = "";

  constructor(data?: IBioFindQuery) {
    super();
    if (data) {
      this.identifier = data.identifier;
      this.value = data.value;
    } else {
      this.identifier = "publicId";
    }
  }
}

export interface IPersonFlags {
  accessCohort?: boolean
  benefits?: boolean
  boosted?: boolean
  canary?: boolean
  enlauSource?: boolean
  fake?: boolean
  featureDiscovery?: boolean
  firstSignalSent?: boolean
  signalsOnboardingCompleted?: boolean
  importingLinkedin?: boolean
  onBoarded?: boolean
  remoter?: boolean
  signalsFeatureDiscovery?: boolean
  importingLinkedinRecommendations?: boolean
  contactsImported?: boolean
  appContactsImported?: boolean
  genomeCompletionAcknowledged?: boolean
  cvImported?: boolean
  communityCreatedPrivate?: boolean
  communityCreatedClaimed?: boolean
  connectBenefitsViewed?: boolean
  recommendationLeadEmailSent?: boolean
  recommendationsAskedGenomeCompletion?: boolean
  opportunityOperator?: boolean
}

export interface IPerson {
  id?: string
  ggId?: string
  subjectId?: number
  publicId?: string
  username?: string
  name: string
  email: string
  phone?: string
  picture: string
  pictureThumbnail: string
  flags: IPersonFlags
  created?: string
  isTorrex: boolean
  theme: string
  locale?: string
}

export interface IExperiencePersonBio {
  id: string,
  category: string,
  name: string
}

export interface IPersonBio {
  experiences: Array<IExperiencePersonBio>
}

export enum ImportCandidatesStep {
  INITIATE_IMPORT = 1,
  SELECT_UPLOAD,
  PREVIEW_VALIDATE,
  IMPORT
}

export enum CandidateImportSourceFile {
  CSV = "csv",
  RESUME = "pdf"
}

export enum CandidateImportStatus {
  PENDING_VALIDATION = "pending-validation",
  PENDING_IMPORT = "pending-import",
  IMPORT_EXISTS = "import-exists",
  IMPORT_SUCCESS = "import-success"
}

export enum CandidateImportType {
  SINGLE = "single",
  BULK = "bulk"
}

export class Person implements IPerson {
  id?: string;
  ggId?: string;
  subjectId?: number;
  publicId?: string;
  name: string;
  email: string;
  phone?: string;
  picture: string;
  pictureThumbnail: string;
  flags: IPersonFlags = {};
  created?: string;
  isTorrex: boolean;
  theme: string = "bio";
  isLoading: boolean = false;
  locale?: string;
  identifierAssignedToGgId: Map<string, string> = new Map();
  isExistingPerson: boolean = false;
  isExistenceVerified: boolean = false;
  linkedInProfileURL?: string;
  socialNetworks?: Array<ISocialNetworkDTO>;

  constructor(data: IPerson) {
    this.id = data?.id;
    this.ggId = data.ggId;
    this.subjectId = data.subjectId;
    this.publicId = data.publicId;
    this.name = data.name;
    this.email = data.email;
    this.phone = data.phone ? String(data.phone).replace(/ /g, "") : undefined;
    this.picture = data.picture;
    this.pictureThumbnail = data.pictureThumbnail;
    this.flags = data.flags || {};
    this.created = data.created;
    this.isTorrex = isTorrexEmail(data.email || "");
    this.theme = data.theme;
    this.locale = data.locale;
  }

  static fromCCGCreationData(data: ICCGCreationWithLinkedinURLData): Person {
    const person = new Person({
      name: data.name,
      email: data.email ?? "",
      phone: data.phone,
      locale: data.locale,
      picture: "",
      pictureThumbnail: "",
      flags: {},
      created: "",
      isTorrex: false,
      theme: "bio"
    });

    person.linkedInProfileURL = data.linkedInProfileURL;

    return person;
  }

  get isEmailAssignedToDiffPerson(): boolean {
    return (
      !isEmpty(this.email) &&
      this.identifierAssignedToGgId.has("email") &&
      this.ggId !== this.identifierAssignedToGgId.get("email")
    );
  }

  get isPhoneAssignedToDiffPerson(): boolean {
    return (
      !isEmpty(this.phone) &&
      this.identifierAssignedToGgId.has("phone") &&
      this.ggId !== this.identifierAssignedToGgId.get("phone")
    );
  }

  get isAlreadyExists(): boolean {
    return (
      !this.isExistingPerson &&
      (this.isEmailAssignedToDiffPerson ||
      this.isPhoneAssignedToDiffPerson)
    );
  }

  get importStatus(): CandidateImportStatus {
    if (
      this.ggId !== undefined &&
      this.isExistenceVerified &&
      !this.isExistingPerson && !this.isAlreadyExists
    ) {
      return CandidateImportStatus.IMPORT_SUCCESS;
    } else if (
      this.ggId !== undefined &&
      this.isExistenceVerified &&
      this.isExistingPerson
    ) {
      return CandidateImportStatus.IMPORT_EXISTS;
    } else if (
      this.ggId === undefined &&
      this.isExistenceVerified &&
      !this.isExistingPerson
    ) {
      return CandidateImportStatus.PENDING_IMPORT;
    } else {
      return CandidateImportStatus.PENDING_VALIDATION;
    }
  }

  getAvailableIdentifiers(): IBioFindQuery[] {
    return [
      { identifier: "email", value: this.email ?? "" },
      { identifier: "phone", value: this.phone ?? "" }
    ].filter(i => !isEmpty(i.value)) as IBioFindQuery[];
  }

  setPersonData(person: IPerson): void {
    this.id = person.id;
    this.ggId = person.ggId;
    this.publicId = person.publicId;
    this.name = person.name;
    this.email = person.email;
    this.phone = person.phone;
    this.picture = person.picture;
    this.pictureThumbnail = person.pictureThumbnail;
    this.flags = person.flags;
    this.created = person.created;
    this.theme = person.theme;
    this.locale = person.locale;
    this.isExistenceVerified = true;
  }

  setCreatorPersonData(person: ICCGCreationResponse): void {
    this.id = person.id;
    this.ggId = person.ggId;
    this.publicId = person.publicId;
  }

  public isTester(): boolean {
    return isTorrexTester(this.email);
  }

  public static Test(): Person {
    return new Person({
      id: "123",
      ggId: "234",
      subjectId: 345,
      publicId: "456",
      name: "Test567",
      email: "test678@torre.co",
      phone: "123456789",
      picture: "",
      pictureThumbnail: "",
      flags: {},
      created: "",
      isTorrex: false,
      theme: "bio"
    });
  }
}

export class AuthUser implements IAuthUser {
  public uid: string;
  public displayName?: string;
  public email: string;
  public phoneNumber?: string;
  public photoURL?: string;
  public role: string;
  public ggId: string;
  public operatorId: number;

  constructor(data: IAuthUser) {
    this.uid = data.uid;
    this.displayName = data.displayName;
    this.email = data.email;
    this.phoneNumber = data.phoneNumber;
    this.photoURL = data.photoURL;
    this.role = data.role;
    this.ggId = data.ggId;
    this.operatorId = data.operatorId;
  }

  public get isTorrex(): boolean {
    return isTorrexEmail(this.email);
  }

  public clone(data?: Partial<IAuthUser>) {
    return new AuthUser({
      uid: data?.uid ?? this.uid,
      displayName: data?.displayName ?? this.displayName,
      email: data?.email ?? this.email,
      phoneNumber: data?.phoneNumber ?? this.phoneNumber,
      photoURL: data?.photoURL ?? this.photoURL,
      role: data?.role ?? this.role,
      ggId: data?.ggId ?? this.ggId,
      operatorId: data?.operatorId ?? this.operatorId
    });
  }

  public static Test(): AuthUser {
    return new AuthUser({
      uid: "123",
      displayName: "Test Name",
      email: "test_username@test.com",
      photoURL: "",
      role: "candidate-recruiter",
      ggId: "123",
      operatorId: 1
    });
  }
}

export class Operator implements IOperator {
  public id: number;
  public bio: ISimplePerson;
  public operatorGgId: string;
  public role: OperatorRoles;
  public isAdmin: boolean;
  public slackId: string;
  public created: Date;
  public permissions: PermissionTinyDTO[];
  public operatorFlags: IOperatorFlagsDTO;

  constructor(data: IOperator) {
    this.id = data.id;
    this.bio = data.bio;
    this.operatorGgId = data.operatorGgId;
    this.role = data.role;
    this.isAdmin = data.isAdmin;
    this.slackId = data.slackId;
    this.created = new Date(data.created);
    this.permissions = data.permissions ?? [];
    this.operatorFlags = data.operatorFlags;
  }

  public getRole(): string {
    return OperatorRolesGeneralOptions.find(x => x.value === this.role)?.key ?? "Unknown";
  }
}

export interface IAuthUserDTO {
  authUser: IAuthUser,
  operator: IOperator
}

export interface IAuthUserWithFirebaseUserDTO extends IAuthUserDTO {
  firebaseUser: firebase.User
}

export interface IOpportunityOperatorDTO {
  id: number,
  opportunityId: number,
  role: OpportunityOperatorRoles,
  ggId: string,
  bio?: ISimplePerson,
  created: string | Date
}

export interface IAssignAccountManagerResponseDTO {
  person: number[]
};

export class OpportunityOperator implements IOpportunityOperatorDTO {
  public id: number;
  public opportunityId: number;
  public role: OpportunityOperatorRoles;
  public ggId: string;
  public bio?: ISimplePerson;
  public created: Date;

  constructor(data: IOpportunityOperatorDTO) {
    this.id = data.id;
    this.opportunityId = data.opportunityId;
    this.role = data.role;
    this.ggId = data.ggId;
    this.bio = data.bio;
    this.created = new Date(data.created);
  }

  public static Test(): OpportunityOperator {
    return new OpportunityOperator({
      id: 1,
      opportunityId: 1,
      role: OpportunityOperatorRoles.RECRUITING_MANAGER,
      ggId: "123",
      bio: {
        id: "1",
        name: "name",
        ggId: "123",
        publicId: "test",
        theme: "bio"
      },
      created: new Date()
    });
  }
}

export const emptySimplePerson = (): ISimplePerson => ({
  id: "",
  publicId: "",
  ggId: "",
  name: "",
  currentRole: "",
  theme: ""
});

export const emptyOperator = (): IOperator => ({
  id: 0,
  bio: emptySimplePerson(),
  operatorGgId: "",
  role: OperatorRoles.CANDIDATE_RECRUITER,
  isAdmin: false,
  slackId: "",
  created: new Date(),
  permissions: [],
  operatorFlags: {
    isAvailable: false,
    speaksEnglish: false,
    isMessagesTaskEnabled: false
  }
});

export interface IUploadCandidatesDBData extends ICCGCreationData {
  linkedinProfileURL?: string;
  personExists: boolean;
  identifierConflict: boolean;
}
