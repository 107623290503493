import { GoogleAuthProvider } from "@firebase/auth";
import { NuxtFireInstance } from "@nuxtjs/firebase";
import { AxiosHeaders } from "axios";
import firebase from "firebase/compat";
import { concatMap, from, Observable, tap } from "rxjs";

import { IAuthUserDTO } from "./DTOs/Person";
import HTTPService from "./HTTPService";
import { mapError } from "@/helpers/ObservableUtils";

class Service extends HTTPService {
  private provider: firebase.auth.AuthProvider = new GoogleAuthProvider().setCustomParameters({ prompt: "select_account" });

  public login(idToken: string): Observable<{message: string}> {
    return this.instantiateCustomHeaders(new AxiosHeaders({ "Cortana-Auth": idToken }))
      .get$<{message: string}>("/api/auth");
  }

  public signOut(): Observable<{message: string}> {
    return this.delete$<{message: string}>("/api/auth");
  }

  public loginWithFirebaseAuth(auth: NuxtFireInstance[ "auth" ]): Observable<IAuthUserDTO> {
    return auth.currentUser
      ? this.fetchOperatorDetail(auth.currentUser!)
      : from(auth.signInWithPopup(this.provider)).pipe(
        concatMap(credentials => this.fetchOperatorDetail(credentials.user!)),
        mapError
      );
  }

  public fetchOperatorDetail(firebaseUser: firebase.User): Observable<IAuthUserDTO> {
    return from(firebaseUser.getIdToken()).pipe(
      concatMap(idToken => this.login(idToken).pipe(
        concatMap(_ => this.instantiateCustomHeaders({ "Cortana-Auth": idToken }).validateToken()),
        mapError
      )),
      mapError
    );
  }

  public logoutWithFirebaseAuth(auth: NuxtFireInstance[ "auth" ]): Observable<void> {
    return from(auth.signOut()).pipe(tap(_ => from(this.signOut())));
  }

  public validateToken(): Observable<IAuthUserDTO> {
    return this.get$<IAuthUserDTO>("/api/auth/validate");
  }
}

export const AuthService = new Service();
